import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBli5qkDZYg3aJ1-knVq_5QaxEaO630Wp8",
    authDomain: "sdhosts-a4685.firebaseapp.com",
    projectId: "sdhosts-a4685",
    storageBucket: "sdhosts-a4685.appspot.com",
    messagingSenderId: "1052962002280",
    appId: "1:1052962002280:web:640098828406a2337b72dd",
    //databaseURL: "SUA_DATABASE_URL"  // Para Realtime Database
};

// Inicializar o Firebase
const app = initializeApp(firebaseConfig);

// Inicializar Firestore
const db = getFirestore(app);

export { db, app };



