<template>
    <div>
        <input type="text" v-model="newTodo" @keyup.enter="addTodo">
        <ul>
            <li v-for="todo in todos" :key="todo.id">
                {{ todo.text }}
            </li>
        </ul>
    </div>
</template>

<script>
    import { db } from '@/firebase' // Importe sua instância do Firebase
   // import { useCollection } from 'vuefire/firestore'

    export default {
        data() {
            return {
                newTodo: '',
            }
        },
        setup() {
            const todos = useCollection(db.collection('todos'))
            return { todos }
        },
        methods: {
            addTodo() {
                db.collection('todos').add({
                    text: this.newTodo,
                    createdAt: new Date()
                })
                this.newTodo = ''
            }
        }
    }
</script>